import React from "react";

import About from "./about";
import Footer from "./footer";
import Hero from "./hero";
import NavBar from "./navbar";
import Clients from "./clients";
import Gallery from "./gallery";
import Projects from "./projects";
import Gap from "./gap";

const MainPage = () => {
  return (
    <div className="w-screen overflow-hidden ">
      <Hero />
      <About />
      <Gap />
      <Projects />
      <Gallery />
      <Clients />
      <Footer />
      <NavBar className="z-50" />
    </div>
  );
};

export default MainPage;
