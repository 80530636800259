import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import SwiperCore, {
  Navigation,
  Pagination,
  Controller,
  Autoplay,
} from "swiper/core";
// install Swiper modules
SwiperCore.use([Navigation, Pagination, Controller, Autoplay]);

const Hero = () => {
  const images = [
    { image: "../assets/20.JPG" },
    { image: "../assets/newPics/7.jpg" },
    { image: "../assets/newPics/5.jpg" },
    { image: "../assets/newPics/6.jpg" },
    { image: "../assets/newPics/1.JPG" },
    { image: "../assets/newPics/2.JPG" },
    { image: "../assets/newPics/3.jpg" },
    { image: "../assets/newPics/4.jpg" },

    {
      image: "../assets/home.JPG",
    },
  ];
  return (
    <div className=" w-screen lg:h-[700px] relative z-0" id="hero">
      <div className="hidden   lg:flex">
        <Swiper
          autoplay={{ delay: 3000 }}
          loop={true}
          className="px-10 w-full "
        >
          {images.map((item, i) => (
            <SwiperSlide key={i}>
              <div className="bg-gray-500/20 w-full  relative lg:h-[700px]">
                <div
                  style={{
                    backgroundImage: `url(${item.image})`,
                    backgroundPosition: "bottom",
                    backgroundSize: "cover",
                    backgroundAttachment: "fixed",
                  }}
                  className="h-full w-full "
                ></div>
                <div className="absolute top-0 w-full bg-black/50 isolate px-6 pt-14 lg:px-8">
                  <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
                    <div className="hidden sm:mb-8 sm:flex sm:justify-center"></div>
                    <div className="text-center mt-[200px]">
                      <h1 className="text-4xl bold font-bold tracking-tight text-white sm:text-6xl">
                        Empowered Women, Empower Women.
                      </h1>
                      <div className="text-[30px] mt-2 font-bold italic text-[#FFFDD0]">
                        Join the Movement!
                      </div>
                    </div>
                  </div>
                  <div
                    className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                    aria-hidden="true"
                  >
                    <div
                      className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                      style={{
                        clipPath:
                          "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                      }}
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {/* mobile */}
      <div className="lg:hidden w-screen relative h-[700px]">
        <div
          style={{
            backgroundImage: `url("../assets/20.JPG")`,
            backgroundPosition: "bottom",
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
          }}
          className="h-full w-full "
        ></div>
        <div className="absolute top-0 w-full h-full flex flex-col justify-end bg-black/50 pb-16 px-6 lg:px-8">
          <div className="mx-auto max-w-2xl  ">
            <div className="text-center">
              <h1 className="text-4xl bold font-bold tracking-tight text-white sm:text-6xl">
                Empowered Women, Empower Women.
              </h1>
              <div className="text-[30px] mt-2 font-bold italic text-[#FFFDD0]">
                Join the Movement!
              </div>
            </div>
          </div>
          <div
            className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
        </div>
      </div>
      {/* /mobile */}
    </div>
  );
};

export default Hero;
