import React from "react";
import AboutPage from "./components/about_page";
import MainPage from "./components/main_page";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

const Router = createBrowserRouter([
  {
    path: "/",
    children: [
      { index: true, element: <MainPage /> },
      {
        path: "/about-us",
        element: <AboutPage />,
      },
    ],
  },
]);

function App() {
  return (
    <div>
      <RouterProvider router={Router} />
    </div>
  );
}

export default App;
