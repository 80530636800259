import React, { useEffect } from "react";
import { BsArrowRight } from "react-icons/bs";

function Projects() {
  const [backdropOn, setBackdropOn] = React.useState(false);
  const [content, setContent] = React.useState({});

  useEffect(() => {
    if (backdropOn) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [backdropOn]);

  const features = [
    {
      name: "HATUA 30 Project",
      icon: "steps",
      more: (
        <div>
          Hatua 30 is a dynamic and impactful initiative that seeks to empower
          and engage Tanzania's youth by increasing their participation in the
          country's public procurement system. Specifically, the project aims to
          mobilize and raise awareness among young people to actively
          participate in securing 30% of the government's procurement contracts
          designated for youth-owned businesses.
          <br />
          <br />
          The implementation of the Hatua 30 project is a collaborative effort
          between Mwanamke na Uongozi and TYVA, two reputable organizations with
          a wealth of experience in advocating for youth empowerment and
          leadership development.
          <br />
          <br />
          The project is set to create a significant impact by providing a
          platform for young entrepreneurs to access government procurement
          opportunities and contribute to the economic growth of Tanzania.
          Through this initiative, the project partners aim to nurture the
          entrepreneurial spirit of the youth, promote innovation and
          creativity, and ultimately create a sustainable future for Tanzania's
          economy.
          <br />
          <br />
          In summary, Hatua 30 is a critical project that has the potential to
          transform the lives of Tanzania's youth, providing them with
          opportunities to showcase their talent and drive economic development
          in the country.
        </div>
      ),
      description:
        "Hatua 30 is a dynamic and impactful initiative that seeks to empower and engage Tanzania's youth by increasing their...",
    },
    {
      name: "WISH Project",
      icon: "Pregnancy",
      more: (
        <div>
          The WISH project, implemented in the Dodoma and Mara regions, had a
          specific goal of promoting accountability and gathering data on health
          financing across all councils within the two regions. Additionally,
          the project aimed to collect performance indicators related to
          reproductive, maternal, newborn, children, and adolescent health,
          which would inform strategic planning for the year 2020.
          <br />
          <br />
          The project was designed to ensure that evidence-based data was
          available for both formal and informal health planning and budgeting
          processes, including the Comprehensive Integrated Plan (CIP). Through
          its activities, the project sought to raise awareness and enhance
          adoption of family planning among the residents of the two regions.
          <br />
          <br />
          The implementation of the WISH project was a collaborative effort
          between Mwanamke na Uongozi and SHIVYAWATA, a federation dedicated to
          championing the rights of Persons with Disabilities (PWDs). The
          partnership aimed to leverage the strengths and resources of both
          organizations to create a more inclusive and equitable healthcare
          system for all.
          <br />
          <br />
          Overall, the WISH project was a crucial initiative that contributed to
          strengthening healthcare planning and budgeting processes while
          promoting reproductive health and family planning in the Dodoma and
          Mara regions.
        </div>
      ),
      description:
        "The WISH project, implemented in the Dodoma and Mara regions, had a specific goal of promoting accountability ...",
    },
    {
      name: "Mwanamke na Kikoba",
      icon: "coin",
      more: <div>more info provided later</div>,
      description:
        "Women in VICOBA. VICOBA is an emerging model that improves the welfare of women, children and households...",
    },
    {
      name: "Binti Kiongozi",
      icon: "Leadership",
      more: (
        <div>
          Women in VICOBA. VICOBA is an emerging model that improves the welfare
          of women, children and households. Women participating in VICOBA
          experienced improved finances and social benefits and gained social
          support networks, which are best described in increased savings and
          assets accumulation, access to health care, decision-making
          capabilities and support for their children’s education.
          <br />
          <br /> MnU conducted dialogue with women from 5 VICOBA which involve
          more than 25 member in each group, Women were able to talk about the
          challenges they face, especially in sexual violence to their partners,
          but also the presence of those challenges have given them access to
          opportunities. Most women who reported Intimate Partner Violence (IPV)
          said that they were reluctant to participate in village community
          banking (VICOBA) or other Community empowerment activity because of
          fear of displeasing their husbands
        </div>
      ),
      description:
        "Binti Kiongozi is an empowering project that provides leadership training and career guidance to young women... ",
    },
    {
      name: "Mwanamke Talk",
      icon: "chat",
      more: (
        <div>
          Mwanamke Talk is a project by Mwanamke na Uongozi that provides a
          platform for ordinary women to share their extraordinary stories,
          creating stronger communities through the power of storytelling. Our
          program and podcast, which is also streamed live on social media,
          celebrates each woman's individuality and unique narrative, creating
          an environment where we can laugh, learn, and inspire each other one
          story at a time. By showcasing the diverse experiences of women, we
          hope to encourage a more inclusive and empathetic society where all
          voices are heard and valued.
        </div>
      ),
      description:
        "Mwanamke Talk is a project by Mwanamke na Uongozi that provides a platform for ordinary women to share their...",
    },
    {
      name: "Superwoman",
      icon: "Superwoman",
      more: (
        <div>
          Superwoman is a collaborative project between Mwanamke na Uongozi and
          Wasafi Media, with the goal of increasing women's participation in
          income-generating activities and supporting them to expand their
          businesses and markets. In addition to promoting economic empowerment,
          Superwoman advocates for the elimination of all forms of violence
          against women and girls, including trafficking and sexual
          exploitation, in both the public and private spheres. The project
          seeks to raise awareness of existing gender inequalities in our
          communities and promote gender equality.
          <br />
          <br />
          To achieve these goals, Superwoman engages with poor and vulnerable
          women to build their capacity to address social, economic, or
          political challenges. The project also involves men and other key
          opinion leaders and stakeholders in the community to engage with the
          community and clarify cultural or religious myths and misconceptions
          that perpetuate gender inequalities.
          <br />
          <br />
          Superwoman recognizes that achieving gender equality requires the
          engagement and participation of all members of society, regardless of
          gender. The project aims to empower women to take charge of their
          lives and become active participants in the economic and social
          development of their communities. Through this approach, Superwoman
          seeks to create a more equitable and just society where women are able
          to live free from violence and discrimination, and fully realize their
          potential.
        </div>
      ),
      description:
        "Superwoman is a collaborative project between Mwanamke na Uongozi and Wasafi Media, with the goal of increasing...",
    },
    {
      name: "Nakataa Vikwazo, Nagombea",
      icon: "nakataa",
      more: (
        <div>
          Mwanamke na Uongozi is committed to empowering young women politicians
          and increasing the number of women in political decision-making bodies
          through its Nakata Vikwazo, Nagombea project, which roughly translates
          to "I am cutting down restrictions, I am vying" in Kiswahili.
          <br />
          <br />
          The project provides young women with opportunities to meet with
          politically experienced women who mentor and offer practical solutions
          to address the challenges they face as women leaders in their
          communities. The project also helps these young women strategize for
          leadership roles in political, economic, and social spaces.
          <br />
          <br />
          Mwanamke na Uongozi is dedicated to promoting women's rights in the
          communities it serves and ensuring gender equity and equality. Through
          its Nakata Vikwazo, Nagombea project, the organization aims to empower
          a new generation of female leaders who will advocate for gender
          equality and help to create more inclusive and representative
          political systems.
        </div>
      ),
      description:
        "Mwanamke na Uongozi is committed to empowering young women politicians and increasing the number of women...",
    },
    {
      name: "Anza na Ulichonacho",
      icon: "donay",
      more: (
        <div>
          "Anza na Ulicho Nacho" (Start With What You Have) - Building a
          Philanthropic Culture Among Working Class Women in Tanzania is a
          project that aims to foster a culture of philanthropy among
          working-class women in Tanzania. The project's primary objective is to
          create a network of female philanthropists who are dedicated to
          improving the living conditions of vulnerable women in their
          communities. These vulnerable groups include young single mothers,
          women living in extreme poverty, women with disabilities, survivors of
          gender-based violence, and others.
          <br />
          <br />
          As a first step, the project has identified vulnerable young single
          mothers as the first group to receive philanthropic support from the
          established network. The project recognizes that many women have a
          natural inclination towards networking and connecting with others, and
          possess a strong sense of community responsibility. This makes them
          well-suited to lead and participate in philanthropic efforts aimed at
          improving the lives of vulnerable women in their
          <br />
          <br />
          The project is designed to create a dynamic balance between the "me"
          and the "us" in philanthropy, where the act of giving is motivated by
          a sense of community responsibility and the desire to improve the
          lives of others. Through well-designed interventions, the project aims
          to establish a network of philanthropic working-class women in
          Tanzania, united in their common goal of helping young, vulnerable
          single mothers.
          <br />
          <br />
          Overall, "Anza na Ulicho Nacho" is a critical project that seeks to
          empower working-class women in Tanzania to become agents of positive
          change in their communities. By creating a network of female
          philanthropists, the project promotes a culture of giving and
          community responsibility, while also providing much-needed support to
          vulnerable groups such as young single mothers.
        </div>
      ),
      description:
        "'Anza na Ulicho Nacho' (Start With What You Have) - Building a Philanthropic Culture Among Working Class...",
    },
    {
      name: "Meet your Mentor",
      icon: "Mentorship",
      more: (
        <div>
          "Meet your Mentor" is a project designed to connect young women and
          aspiring professionals with experienced and successful mentors who can
          offer guidance, advice and support to help them achieve their career
          goals. The project aims to provide a platform for networking and
          knowledge sharing, bringing together individuals from diverse
          backgrounds and industries to exchange ideas and learn from each
          other's experiences.
          <br />
          <br />
          The project will provide regular networking events and mentorship
          sessions where mentees can interact with their mentors and other
          industry professionals to learn about the skills and knowledge
          required for success in their chosen fields. These events will also
          include workshops and training sessions on various topics such as
          resume writing, interview skills, communication, and leadership.
          <br />
          <br />
          The project will also leverage digital platforms to provide virtual
          mentorship opportunities, allowing mentees to connect with their
          mentors remotely and access resources and support anytime, anywhere.
          This will include online mentoring sessions, webinars, and other
          resources to help mentees navigate their career journeys.
          <br />
          <br />
          The "Meet Your Mentor" project aims to empower young professionals and
          equip them with the skills and knowledge needed to succeed in their
          careers. By providing access to experienced mentors and a supportive
          community, the project will help young women professionals achieve
          their full potential and make meaningful contributions to their
          industries and communities.
        </div>
      ),
      description:
        "'Meet your Mentor' is a project designed to connect young women and aspiring professionals with experienced...",
    },
  ];
  return (
    <div
      className="w-screen flex border-red-900/50 text-white justify-center"
      id="projects"
    >
      {backdropOn && (
        <Backdrop
          content={content}
          setContent={setContent}
          setBackdropOn={setBackdropOn}
        />
      )}

      <div className="w-full p-10 lg:max-w-[1024px] ">
        <div className="  sm:py-4">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="font-1 flex justify-center gap-x-2 color mx-2 md:text-5xl text-3xl pb-10">
              Projects
            </div>
            <div className="mx-auto  max-w-2xl  lg:max-w-4xl">
              <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                {features.map((feature, index) => (
                  <div
                    key={feature.name}
                    className="relative pl-16 border hover:-translate-y-5 transition-all duration-500 border-red-900/30 rounded-xl p-2"
                  >
                    <dt className="text-base font-semibold leading-7 text-black">
                      <div className="absolute left-2 top-2">
                        <img
                          src={`../assets/icons/${feature.icon}.png`}
                          alt="logo_image"
                          className="h-12  "
                        />
                      </div>
                      {feature.name}
                    </dt>
                    <dd className="mt-2 text-sm leading-7 text-black/80">
                      {feature.description}
                    </dd>
                    <div
                      onClick={() => {
                        setBackdropOn(true);
                        setContent({
                          data: feature.more,
                          title: feature.name,
                          icon: feature.icon,
                        });
                      }}
                      className="color flex items-center gap-x-2 cursor-pointer hover:text-black"
                    >
                      See More
                      <BsArrowRight />
                    </div>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;

const Backdrop = ({ content, setContent, setBackdropOn }) => {
  return (
    <div className="w-screen h-screen fixed top-0 z-30 backdrop-blur-xl flex items-center px-2 bg-black/20 justify-center">
      <div className="w-full p-10 lg:max-w-[1024px] bg-white/20 backdrop-blur-xl rounded-xl h-[80%] relative overflow-y-scroll">
        <div
          onClick={() => {
            setContent({});
            setBackdropOn(false);
          }}
          className="absolute right-10 top-10 cursor-pointer hover:bg-red-900 flex px-3 py-1 items-center justify-center rounded-xl bg-white/40"
        >
          close
        </div>
        <div className="flex items-center gap-x-3">
          <img
            src={`../assets/icons/${content.icon}.png`}
            alt="logo_image"
            className="h-12  "
          />
          <div className="text-xl">{content.title}</div>
        </div>
        <div className="h-[1px] w-full bg-white/30 my-4" />
        <div>{content.data}</div>
      </div>
    </div>
  );
};
