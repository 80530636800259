import React from "react";
import CountUp from "react-countup";
import { HiOutlineArrowRight } from "react-icons/hi2";
import { NavLink } from "react-router-dom";

const About = () => {
  return (
    <div
      className=" w-screen flex justify-center relative bg-gray-100 py-10  items-center"
      id="about"
      style={{
        backgroundImage: `url(${"../assets/pattern.png"})`,
        backgroundPosition: "bottom",
        backgroundSize: "cover",
        backgroundBlendMode: "multiply",
      }}
    >
      <div className="w-full  max-w-[1024px] flex flex-col items-center">
        <div className="font-1 flex itmes-center gap-x-2 color mx-2 md:text-5xl text-3xl pb-10">
          About Us
        </div>
        <div className="w-full text-center text-gray-700 ">
          <div className="mb-10 mx-4 lg:mx-0" align="justify">
            Mwanamke na Uongozi is a non-profit organization based in Tanzania
            that is dedicated to promoting women's leadership and ensuring that
            women have equal opportunities to participate fully and effectively
            in leadership and decision-making processes at all levels of
            society. The organization recognizes the significant role that women
            can play in driving positive change in their communities and seeks
            to empower women to take on leadership roles in various spheres of
            influence such as social, economical and political.
            <br />
            <br /> Our organization's mission is to increase the number of women
            in leadership positions, support their professional development, and
            advocate for policies and practices that promote gender equality in
            leadership. Through various programs and initiatives, Mwanamke na
            Uongozi works to build women's leadership skills and confidence,
            provide networking and mentorship opportunities, and encourage women
            to pursue leadership roles in both the public, private and
            development sectors.
            <br />
            <div className="w-full flex justify-center mt-2">
              <NavLink
                to="about-us"
                className="color text-lg flex gap-x-2 items-center border-2 cursor-pointer hover:bg-pink-900 hover:text-white border-pink-900 px-3 py-2"
              >
                Read More <HiOutlineArrowRight />
              </NavLink>
            </div>
          </div>

          <div className="mb-10">
            <div className="text-[40px] font-black">Our Impact</div>
            <div className="flex flex-wrap gap-y-10 lg:gap-y-0 gap-x-10 justify-center">
              <div>
                <div className="text-[40px] color font-black bold">
                  <CountUp end={5500} duration={4} suffix="+" />
                </div>
                <div className="font-bold">
                  Young women trained on Leadership Skills
                </div>
              </div>
              <div>
                <div className="text-[40px] color font-black bold">
                  {" "}
                  <CountUp
                    end={10000000}
                    duration={4}
                    suffix="+"
                    separator=","
                  />
                </div>
                <div className="font-bold">Women reached through media</div>
              </div>
              <div>
                <div className="text-[40px] color font-black bold">
                  {" "}
                  <CountUp end={50000} duration={4} suffix="+" separator="," />
                </div>
                <div className="font-bold">
                  Women reached through Outreach programs
                </div>
              </div>
              <div>
                <div className="text-[40px] color font-black bold">
                  {" "}
                  <CountUp end={124} duration={4} />
                </div>
                <div className="font-bold">Partnerships formed</div>
              </div>
            </div>
          </div>

          <br />
        </div>
      </div>
    </div>
  );
};

export default About;
