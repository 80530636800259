import React from "react";
import CountUp from "react-countup";
import { HiOutlineArrowRight } from "react-icons/hi2";
import { NavLink } from "react-router-dom";

const AboutPage = () => {
  const thermals = [
    {
      icon: "Leadership",
      name: "Leadership",
    },
    { icon: "cap", name: "Capacity building" },
    { icon: "wish", name: "Health" },
    { icon: "Contract", name: "Education" },
    { icon: "note", name: "Economy" },
    { icon: "donay", name: "Livelihood" },
  ];
  return (
    <div>
      {" "}
      <div
        className=" w-screen flex justify-center relative bg-gray-100 py-10  items-center"
        id="about"
        style={{
          backgroundImage: `url(${"../assets/pattern.png"})`,
          backgroundPosition: "bottom",
          backgroundSize: "cover",
          backgroundBlendMode: "multiply",
        }}
      >
        <div className="w-full  max-w-[1024px] flex flex-col items-center">
          <div className="font-1 color mx-2 md:text-5xl text-3xl pb-10">
            About Us
          </div>
          <div className="w-full text-center text-gray-700 ">
            <div className="mb-10 mx-4" align="justify">
              Mwanamke na Uongozi is a non-profit organization based in Tanzania
              that is dedicated to promoting women's leadership and ensuring
              that women have equal opportunities to participate fully and
              effectively in leadership and decision-making processes at all
              levels of society. The organization recognizes the significant
              role that women can play in driving positive change in their
              communities and seeks to empower women to take on leadership roles
              in various spheres of influence such as social, economical and
              political.
              <br />
              <br /> Our organization's mission is to increase the number of
              women in leadership positions, support their professional
              development, and advocate for policies and practices that promote
              gender equality in leadership. Through various programs and
              initiatives, Mwanamke na Uongozi works to build women's leadership
              skills and confidence, provide networking and mentorship
              opportunities, and encourage women to pursue leadership roles in
              both the public, private and development sectors.
              <br />
              <br />
              Mwanamke na Uongozi recognizes that gender inequality and
              discrimination against women remain major obstacles to women's
              participation in leadership roles. As such, the organization seeks
              to address these issues through advocacy, education, and outreach
              efforts. The organization works to raise awareness of the
              importance of women's leadership, challenge gender stereotypes,
              and promote policies and practices that support gender equality in
              leadership.
              <br />
              <br />
              Overall, Mwanamke na Uongozi is committed to promoting gender
              equality and empowering women to take on leadership roles in
              Tanzania and across the region. The organization's work is
              critical to advancing women's rights, promoting social justice,
              and building a more equitable and inclusive society for all.
            </div>
            <div className="w-full flex flex-col items-center pb-10">
              {" "}
              <div className="font-1 color mx-2 md:text-5xl text-3xl ">
                Our Vision
              </div>
              <p>
                A society which women and girls are empowered and able to
                realize their full leadership potential
              </p>
            </div>
            <div className="w-full bg-white py-10 rounded-xl">
              <div className="font-1 color mx-2 md:text-5xl text-3xl ">
                Thematic Areas
              </div>
              <div className="w-full flex justify-center my-10 ">
                <div className="flex flex-wrap lg:gap-x-20 gap-x-10 justify-center gap-y-10 lg:gap-y-0">
                  {thermals.map((items) => (
                    <div className=" flex items-center justify-center flex-col gap-y-2 ">
                      <div className="h-16 w-16 rounded-full border bg-white/10  border-white/20 flex justify-center items-center text-white">
                        <img src={`../assets/icons/${items.icon}.png`} />
                      </div>
                      <div className="bold">{items.name}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="my-10">
              <div className="text-[40px] font-black">Our Impact</div>
              <div className="flex gap-x-10 flex-wrap gap-y-10 lg:gap-y-0 justify-center">
                <div>
                  <div className="text-[40px] color font-black bold">
                    <CountUp end={5500} duration={4} suffix="+" />
                  </div>
                  <div className="font-bold">
                    Young women trained on Leadership Skills
                  </div>
                </div>
                <div>
                  <div className="text-[40px] color font-black bold">
                    {" "}
                    <CountUp
                      end={10000000}
                      duration={4}
                      suffix="+"
                      separator=","
                    />
                  </div>
                  <div className="font-bold">Women reached through media</div>
                </div>
                <div>
                  <div className="text-[40px] color font-black bold">
                    {" "}
                    <CountUp
                      end={50000}
                      duration={4}
                      suffix="+"
                      separator=","
                    />
                  </div>
                  <div className="font-bold">
                    Women reached through Outreach programs
                  </div>
                </div>
                <div>
                  <div className="text-[40px] color font-black bold">
                    {" "}
                    <CountUp end={124} duration={4} />
                  </div>
                  <div className="font-bold">Partnerships formed</div>
                </div>
              </div>
            </div>
            <div className="w-full flex justify-center ">
              <img
                src="../assets/hero.JPG"
                alt="logo_image"
                className="lg:h-[500px] lg:w-[1024px] object-cover"
              />
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
