import React from "react";

const Clients = () => {
  const client_data = [
    {
      image: "../assets/logos/logo-15.png",
    },
    {
      image: "../assets/logos/logo-1.png",
    },

    {
      image: "../assets/logos/logo-3.png",
    },
    {
      image: "../assets/logos/logo-4.png",
    },
    {
      image: "../assets/logos/logo-5.png",
    },
    {
      image: "../assets/logos/logo-6.png",
    },
    {
      image: "../assets/logos/logo-7.png",
    },
    {
      image: "../assets/logos/logo-8.png",
    },
    {
      image: "../assets/logos/logo-9.png",
    },
    {
      image: "../assets/logos/logo-10.png",
    },
    {
      image: "../assets/logos/logo-11.png",
    },
    {
      image: "../assets/logos/logo-12.png",
    },
    {
      image: "../assets/logos/logo-13.png",
    },
    {
      image: "../assets/logos/logo-2.png",
    },
    {
      image: "../assets/logos/logo-14.png",
    },
  ];

  const ClientContainer = ({ image }) => {
    return (
      <div className=" group  relative">
        <img
          alt="client-image"
          src={image}
          className=" h-full w-full object-contain group-hover:scale-110 transition-all duration-500"
        />
      </div>
    );
  };
  return (
    <div
      className=" w-screen relative flex justify-center bg-white py-8 lg:py-[100px] items-center "
      id="partners"
      style={{
        backgroundImage: `url(${"../assets/pattern-3.png"})`,
        backgroundPosition: "bottom",
        backgroundSize: "cover",
        backgroundBlendMode: "multiply",
      }}
    >
      <div className="w-full lg:max-w-[1024px] flex flex-col items-center">
        <div className="font-1 color mx-2 md:text-5xl text-3xl pb-10">
          Partners
        </div>

        <div className="w-full grid grid-cols-2 lg:grid-cols-5 px-[50px]  gap-x-10 gap-y-5 justify-center ">
          {client_data.map((element) => (
            <ClientContainer image={element.image} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Clients;
