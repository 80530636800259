import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import SwiperCore, {
  Navigation,
  Pagination,
  Controller,
  Autoplay,
} from "swiper/core";
// install Swiper modules
SwiperCore.use([Navigation, Pagination, Controller, Autoplay]);

const Gallery = () => {
  const galleryData = [
    {
      image: "1.jpg",
    },
    {
      image: "2.jpg",
    },
    {
      image: "newPics/diva.jpg",
    },
    {
      image: "4.jpg",
    },
    {
      image: "5.jpg",
    },
    {
      image: "6.jpg",
    },
    {
      image: "7.jpg",
    },
    {
      image: "8.jpg",
    },
    {
      image: "9.jpg",
    },
    {
      image: "10.jpg",
    },
    {
      image: "11.jpg",
    },
    {
      image: "12.jpg",
    },
    {
      image: "newPics/all.jpg",
    },
    {
      image: "newPics/13.jpg",
    },
    {
      image: "newPics/14.jpg",
    },
    {
      image: "newPics/16.JPG",
    },
    {
      image: "newPics/17.jpg",
    },
    {
      image: "newPics/18.JPG",
    },
    {
      image: "newPics/12.JPG",
    },
    {
      image: "newPics/20.jpg",
    },
    {
      image: "newPics/21.jpg",
    },
  ];

  const Show = ({ image }) => {
    return (
      <div
        className="text-sm group border grayscale hover:grayscale-0 border-red-900/50 rounded-lg bg-blue-100  mainClip  w-[200px] h-[170px] lg:h-[200px] lg:w-[250px] flex  items-end  overflow-hidden relative"
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className=" bg-opacity-10  w-full clip group   h-[200px] overflow-hidden absolute transition-all duration-500">
          <div
            style={{
              backgroundImage: `url(${image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            className="w-full h-full absolute  group-hover:scale-125 transition-all duration-500"
          />
          <div className="flex flex-col items-start p-4 bg-black bg-opacity-20  justify-end h-full"></div>
        </div>
      </div>
    );
  };
  return (
    <div
      className=" w-screen relative flex justify-center py-[50px] bg-red-900/20 bg-gray-100 items-center "
      id="gallery"
    >
      <div className="w-full lg:max-w-[1024px] flex flex-col items-center">
        <div className="font-1 color mx-2  pb-10 md:text-5xl text-3xl">
          Gallery
        </div>

        <div className="w-full px-2">
          <Swiper
            spaceBetween={10}
            slidesPerView={2}
            autoplay={{ delay: 4000 }}
            loop={true}
            updateOnWindowResize
            observer
            observerParents
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
            }}
            className="w-full"
          >
            {galleryData.map((element) => (
              <SwiperSlide>
                <Show image={`../assets/${element.image}`} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
