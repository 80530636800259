import React from "react";
import { Link } from "react-scroll";
import { IoCloseOutline } from "react-icons/io5";
import { HiBars3BottomLeft } from "react-icons/hi2";

const NavBar = () => {
  const [open, setOpen] = React.useState(false);
  return (
    <div className="w-screen">
      {/* DESKTOP */}
      <div className="w-full hidden lg:flex top-0 fixed justify-between px-4   py-4 ">
        <Link
          className="flex items-center text-sm group px-6 flex-col cursor-pointer hover:scale-110 transition-all duration-200 hover:text-black "
          to="hero"
          duration={500}
          smooth={true}
          offset={0}
        >
          <div>
            <img src="../assets/brand.png" alt="logo_image" className="h-14" />
          </div>
        </Link>
        {/* OTHER LINKS */}
        <div className="flex items-center text-white bg-gradient-to-r from-[#75224d] to-[#FF0084] mx-6">
          <Link
            className="flex items-center text-sm group px-6 flex-col cursor-pointer hover:scale-110 transition-all duration-200 hover:text-[#FFFDD0] "
            to="hero"
            duration={500}
            smooth={true}
            offset={0}
          >
            <div>Home</div>
          </Link>
          <Link
            className="flex items-center text-sm group px-6 flex-col cursor-pointer hover:scale-110 transition-all duration-200 hover:text-[#FFFDD0] "
            to="about"
            duration={500}
            smooth={true}
            offset={-60}
          >
            <div>About</div>
          </Link>

          <Link
            className="flex items-center text-sm group px-6 flex-col cursor-pointer hover:scale-110 transition-all duration-200 hover:text-[#FFFDD0] "
            to="projects"
            duration={500}
            smooth={true}
            offset={0}
          >
            <div>Projects</div>
          </Link>

          <Link
            className="flex items-center text-sm group px-6 flex-col cursor-pointer hover:scale-110 transition-all duration-200 hover:text-[#FFFDD0] "
            to="gallery"
            duration={500}
            smooth={true}
            offset={-60}
          >
            <div>Resources</div>
          </Link>

          <Link
            className="flex items-center text-sm group px-6 flex-col cursor-pointer hover:text-[#FFFDD0] hover:scale-110 transition-all duration-200 "
            to="partners"
            duration={500}
            smooth={true}
            offset={-60}
          >
            <div>Partners</div>
          </Link>

          <Link
            className="flex items-center text-sm group px-6 flex-col cursor-pointer hover:text-[#FFFDD0] hover:scale-110 transition-all duration-200 "
            to="partners"
            duration={500}
            smooth={true}
            offset={-60}
          >
            <div>News</div>
          </Link>

          <Link
            className="flex items-center text-sm group px-6 flex-col cursor-pointer hover:text-[#FFFDD0] hover:scale-110 transition-all duration-200 "
            to="contacts"
            duration={500}
            smooth={true}
            offset={-60}
          >
            <div>Contacts</div>
          </Link>
        </div>
        {/* OTHER LINKS */}
      </div>
      {/* DESKTOP */}

      {/* mobile */}
      <div className="w-full lg:hidden  border-t  top-0 flex fixed  flex-col  px-4 bg-gradient-to-r from-[#75224d] to-[#FF0084]  py-2 backdrop-blur-lg border-b border-black border-opacity-10">
        <div className="flex justify-between items-center text-white">
          <img src="../assets/brand.png" alt="logo" className="h-6" />

          {!open ? (
            <HiBars3BottomLeft
              size={30}
              onClick={() => {
                setOpen(true);
              }}
            />
          ) : (
            <IoCloseOutline
              size={30}
              onClick={() => {
                setOpen(false);
              }}
            />
          )}
        </div>
        {/* OTHER LINKS */}

        <div
          className={` ${
            open
              ? "h-[200px] flex  items-start flex-col justify-start transition-all duration-200 "
              : "h-[0px] flex  items-start flex-col justify-start transition-all duration-200 overflow-hidden"
          } text-white gap-y-2 `}
        >
          <hr className="mt-6 w-full" />

          <Link
            className="flex items-center text-sm group px-2 flex-col cursor-pointer hover:scale-110 transition-all duration-200 hover:text-orange-400 "
            to="hero"
            duration={500}
            smooth={true}
            offset={0}
            onClick={() => {
              setOpen(false);
            }}
          >
            <div>HOME</div>
          </Link>

          <Link
            className="flex items-center text-sm group px-2 flex-col cursor-pointer hover:scale-110 transition-all duration-200 hover:text-orange-400 "
            to="projects"
            duration={500}
            smooth={true}
            offset={20}
            onClick={() => {
              setOpen(false);
            }}
          >
            <div>PROJECTS</div>
          </Link>
          <Link
            className="flex items-center text-sm group px-2 flex-col cursor-pointer hover:scale-110 transition-all duration-200 hover:text-orange-400 "
            to="about"
            duration={500}
            smooth={true}
            offset={0}
            onClick={() => {
              setOpen(false);
            }}
          >
            <div>ABOUT</div>
          </Link>
          <Link
            className="flex items-center text-sm group px-2 flex-col cursor-pointer hover:scale-110 transition-all duration-200 hover:text-orange-400 "
            to="gallery"
            duration={500}
            smooth={true}
            offset={-70}
            onClick={() => {
              setOpen(false);
            }}
          >
            <div>GALLERY</div>
          </Link>
          <Link
            className="flex items-center text-sm group px-2 flex-col cursor-pointer hover:text-orange-400 hover:scale-110 transition-all duration-200 "
            to="partners"
            duration={500}
            smooth={true}
            onClick={() => {
              setOpen(false);
            }}
          >
            <div>PARTNERS</div>
          </Link>
          <Link
            className="flex items-center text-sm group px-2 flex-col cursor-pointer hover:text-orange-400 hover:scale-110 transition-all duration-200 "
            to="contacts"
            duration={500}
            smooth={true}
            onClick={() => {
              setOpen(false);
            }}
          >
            <div>CONTACTS</div>
          </Link>
        </div>
        {/* OTHER LINKS */}
      </div>
      {/* mobile */}
    </div>
  );
};

export default NavBar;
