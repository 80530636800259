import React from "react";

const Gap = () => {
  return (
    <div>
      <div
        style={{
          backgroundImage: `url("../assets/newPics/masai.JPG")`,
          backgroundPosition: "bottom",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
        className="h-[400px] w-full "
      >
        <div className="bg-black/70 w-full h-full"></div>
      </div>
    </div>
  );
};

export default Gap;
